import React from "react";

import Layout from "../../../components/layout";
import SmallBanner from "../../../components/smallBanner";

const Quality = () => {
  const lang = "en";

  return (
    <Layout
      seoTitle="Quality"
      lang={lang}
      translationPL="/oferta/jakosc/"
      translationDE="/de/angebot/qualitat/"
    >
      <SmallBanner
        title="Quality"
        aditionalBreadcrump="Our services"
        aditionalBreadcrumpLink="/en/our-services/"
      />
      <section className="text-section quality-info text-section--last">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
              <h2 className="small color--main mb-60">
                PalettenWerk’s quality <br /> control system
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-3 col-xl-4">
              <img
                className="quality-info__left-image"
                src={require("../../../assets/img/quality2.jpg")}
                alt="Jakość"
              />
            </div>
            <div className="col-12 col-lg-9 col-xl-8">
              <div className="text-section__content">
                <p className="mb-30">
                  PalettenWerk has a 6-stage quality control system, which
                  guarantees the repeatability, durability, and long life of the
                  pallets. The system includes control of the delivery of pallet
                  components and control of the production process and finished
                  products. All control processes are integrated into a quality
                  management system.
                </p>
                <p className="mb-60">
                  Quality control starts with the suppliers of the components
                  used to produce pallets and serves to eliminate potential
                  irregularities that may occur when preparing the raw material
                  for production. The pallet components are also inspected when
                  entering the plant and at each workstation they pass through.
                </p>
                <img
                  src={require("../../../assets/img/quality1.jpg")}
                  alt="Jakość"
                />
                <div className="content__qulity-check">
                  <div className="qulity-check__item">
                    <h4 className="item__title bold mb-50">
                      Production process control
                    </h4>
                    <div className="item__content ">
                      <p className="content__item">
                        Inspection of pallet <br /> components at suppliers
                      </p>
                      <p className="content__item">
                        Inspection whilst <br />
                        receiving deliveries
                      </p>
                      <p className="content__item">
                        Internal inspection <br /> at the production stage
                      </p>
                    </div>
                  </div>
                </div>
                <div className="content__qulity-check">
                  <div className="qulity-check__item">
                    <h4 className="item__title bold mb-50">
                      Inspection of finished products
                    </h4>
                    <div className="item__content">
                      <p className="content__item">
                        Internal inspection <br /> of finished products
                      </p>
                      <p className="content__item">
                        External inspection <br />
                        of finished products
                      </p>
                      <p className="content__item">
                        External audit of <br /> Euro-pallets for compliance{" "}
                        <br /> with the standard UIC 435-2
                      </p>
                    </div>
                    <p className="item__bottom-info bold">
                      ISO 9001:2015 certification
                    </p>
                  </div>
                </div>
                <div className="content__qulity-check content__qulity-check--ready">
                  <div className="qulity-check__item">
                    <h4 className="item__title bold mb-50">
                      Finished products
                    </h4>
                    <div className="item__content">
                      <p className="content__item">EPAL certificate</p>
                      <img
                        className="content__item"
                        src={require("../../../assets/img/palete-quality.png")}
                        alt="EPAL certificate"
                      />
                      <p className="content__item">IPPC certificate</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Quality;
